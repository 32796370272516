<template>
  <span>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      dense
      
      sort-by="date"
      sort-desc
      :headers="headers"
      :items="refuels"
      :search="search"
      :items-per-page="5"
      class="elevation-1"
    >
      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
  </span>
</template>
  
<script>
  import store from '../../../store/index';

  
    export default {
      components: {
       
      },
      props: {
        carname: String
      },
      data: () => ({
        headers: [
          { text: 'Date', value: 'date'},
          { text: 'Fuel type', value: 'type' },
          { text: 'Price (€)', value: 'price' },
          { text: 'liters (L)', value: 'liters' },
          { text: 'Price/Liter (€/L)', value: 'litPrice' },
          { text: 'Total Km', value: 'total' },
          { text: 'Partial Km', value: 'partial' },
          { text: 'conso (L/100Km)', value: 'conso' },
          { text: 'Actions', value: 'actions', sortable: false },
        ],
        search: '',
      }),
      methods: {
        // async computekm () {
        //   var lastkm = 0
        //   const carbu = store.getters.get_carburant(this.carname)
        //   var updated = []
        //   var tri = {}
        //   carbu.forEach(element => {
        //     tri[element.total] = element
        //   });
        //   Object.keys(tri).sort().forEach(function(k) {
        //     console.log(k, tri[k]);
        //     tri[k]["partial"] = tri[k]["total"] - lastkm
        //     tri[k]["conso"] = tri[k]["liters"] / tri[k]["partial"] * 100
        //     updated.push(tri[k])
        //     lastkm = tri[k]["total"]
        //   });
        //   await this.writeFile(store.state.podurl + "/" + this.carname + "/carburant.json", carbu)
          
        //   store.commit('set_carburant', {carname: this.carname, data: carbu})
        // },

        async deleteItem (item) {
          const carbu = store.getters.get_carburant(this.carname)
          this.editedIndex = carbu.indexOf(item)
          carbu.splice(this.editedIndex, 1)
          //await this.writeFile(store.state.podurl + "/" + this.carname + "/carburant.json", carbu)
          
          store.commit('set_carburant', {carname: this.carname, data: carbu})
          
        },
       
      },
      computed: {
        refuels () {
          return store.getters.get_carburant(this.carname)
        },
      },
       
    }
</script>

<style>

</style>