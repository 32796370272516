<template>
  <span>
    <v-row class="fill-width pa-1">
      <v-btn v-for="car in carList" :key="car" class="ma-1" @click="goToRefuel(car)">{{car}}</v-btn>
    </v-row>
    <v-expansion-panels  class="pa-1" >
      <v-expansion-panel >
        <v-expansion-panel-header>add vehicules</v-expansion-panel-header>
        <v-expansion-panel-content>
          <Addcar/>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel >
        <v-expansion-panel-header>next deadlines</v-expansion-panel-header>
        <v-expansion-panel-content>
        vidanges...
        frein
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </span>
</template>

<script>
import store from '../store/index';
import Addcar from './home/addcar.vue'

export default {
    computed: {
      carList () {
        return store.state.carList
      },
    },
    components: {
      Addcar,
    },
    methods: {
      goToRefuel (car) {
        //this.$parent.goToRefuel(car)
        this.$emit('goToRefuel', car)
      },
    },
}
</script>

<style>
.fill-width {
  overflow-x: auto;
  flex-wrap: nowrap;
  margin: auto;
}
</style>