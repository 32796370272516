<template>
  <span>
    <v-expansion-panels multiple accordion class="pa-1"  v-model="open">
            <v-expansion-panel >
                <v-expansion-panel-header>add refuel</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <Refuel :carname="carname"/>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel >
                <v-expansion-panel-header>refuel history</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <!-- {{plein}} -->
                    <refuel-history :carname="carname"/>

                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
  </span>
</template>

<script>
import store from '../../store/index';
import Refuel from './carburant/refuel.vue';
import RefuelHistory from './carburant/refuelHistory.vue';

export default {
    props: {
      carname: String,
      openRefuel: Boolean,
    },
    watch: {
      openRefuel: {
          immediate: true, 
          handler () {
              console.log("openRefuel")
              if (!this.open.includes(0)) {
                  this.open.push(0)
              }
          }
      }
    },
    data: () => ({
        open: [],
    }),
    components: {
      Refuel,
      RefuelHistory,
    },
    computed: {
      plein () {
        return store.getters.get_carburant(this.carname)
        // console.log("comp")
        // console.log(Object.keys(store.state.carburant))
        // console.log(store.state.carburant)
        // return store.state.carburant
        
      },
    },
    // async created(){
    //     var res = this.$parent.readFile(store.state.podurl + "/" + this.carname + "/carburant.json")
    //     res = JSON.parse(await Promise.resolve(res))
    //     store.commit('set_carburant', {carname: this.carname, data: res})
    //     console.log("carb")
    //     console.log(store.state.carburant)
    // },
}
</script>

<style>

</style>