<template>
  <validation-observer ref="observer" v-slot="{ invalid }">
    <form @submit.prevent="submit">
      <validation-provider
        v-slot="{ errors }"
        name="Name of the vehicule"
        :rules="{
          required: true,
          max: 20,
          regex: '^[a-zA-Z0-9]+$',
        }"
      >
        <v-text-field
          v-model="name"
          :counter="20"
          :error-messages="errors"
          label="Name of the vehicule"
          required
        ></v-text-field>
      </validation-provider>

      <validation-provider v-slot="{ errors }" name="select" rules="required">
        <v-select
          v-model="select"
          :items="items"
          :error-messages="errors"
          label="select the type of the vehicule"
          required
        ></v-select>
      </validation-provider>
      <v-textarea
        v-model="description"
        label="description of the vehicule"
      ></v-textarea>

      <v-btn class="mr-4" type="submit" :disabled="invalid"> submit </v-btn>
      <v-btn @click="clear"> clear </v-btn>
    </form>
  </validation-observer>
</template>

<script>
import { required, max, regex } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";
import store from "../../store/index";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "{_field_} can not be empty",
});

extend("max", {
  ...max,
  message: "{_field_} may not be greater than {length} characters",
});

extend("regex", {
  ...regex,
  message: "{_field_} {_value_} does not match {regex}",
});

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data: () => ({
    name: "",
    select: null,
    items: ["termique", "hybride", "gpl"],
    description: "",
  }),
  methods: {
    async writeFile(url, json) {
      return await this.$parent.writeFile(url, json);
    },
    async submit() {
      console.log("valid");
      this.$refs.observer.validate();
      const json = {
        name: this.name,
        type: this.select,
        description: this.description,
      };
      await this.writeFile(
        store.state.podurl + "/" + this.name + "/general.json",
        json
      );
      await this.writeFile(
        store.state.podurl + "/" + this.name + "/carburant.json",
        []
      );
      await this.writeFile(
        store.state.podurl + "/" + this.name + "/maintenance.json",
        []
      );
      store.commit("add_car", this.name);
    },
    clear() {
      this.name = "";
      this.select = null;
      this.description = "";
      this.$refs.observer.reset();
    },
  },
};
</script>

<style></style>
