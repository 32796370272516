<template>
    <div class="login">

        <!-- No webId  -->
        <!-- <span style="text-align: center;">
            <h2>! this website is a work in progress !</h2>
            <p>Some features may be missing and added later. Nothing should be a breaking change, but if it is all your data should be safe on your pod anyway.
                <br/>A language support will be added later, at least for French and English, but for now there might be a mix of both (it should be mostly English)
            </p>
        </span> -->
        <div class="form" >
      <!-- <button  @click="loginAndFetch">Login</button><br/>
      <button  @click="logout">Logout</button> -->

            <h2>Please select your identity provider or type it yourself:</h2>
            <v-combobox 
            clearable
            v-model="selectValue"
            :items="options"/>
            <!-- <select v-model="selectValue"  @change="selectProvider">
                <option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </option>
            </select> -->
            <v-btn @click="submit" >Submit</v-btn>
        </div>

        <!-- Has webId  -->
        <div v-if="this.login">

            <!-- WebId  -->
            <h2>Your webId is : {{ this.webId }}</h2>

            <!-- Username  -->
            <h2 v-if="this.profile !== null">Your name is : {{ this.userName }}</h2>

            <!-- Notepad  -->
            <div v-if="this.notePad !== null" class="notepad">
                <h2>{{ this.userName }}'s NotePad</h2>
                <!-- <input placeholder="Add note..." v-model="newNote">
                    <button @click="addNote" slot="append" icon="icon-edit"></button>
                </input> -->
                <h2 class="notes" v-for="note in notes" :key="note">{{ note }}</h2>
            </div>

            <!-- Get Name button  -->
            <button @click="getName" class="" type="general" plain>Get Your Profile</button>

            <!-- Get Notepad button  -->
            <button v-if="this.profile !== null" @click="getNotesList" class="" type="general" plain>Get Note List
            </button>

            <button v-if="this.profile !== null" @click="getMyData" class="" type="general" plain>Get my data
            </button>

            <!-- Logout button -->
            <button @click="logout" class="submitButton" type="danger" plain>Log out</button>
        </div>

    </div>
</template>

<script>
// import auth from "solid-auth-client";
// import {fetchDocument, createDocument} from 'tripledoc';
// import {foaf, solid, schema, space, rdf} from 'rdf-namespaces';
import {getPodUrlAll, getContainedResourceUrlAll} from "@inrupt/solid-client";
// import { getFile, isRawData, getContentType, getSourceUrl, getPodUrlAll, getContainedResourceUrlAll} from "@inrupt/solid-client";
//import { login, getSession, fetch } from 'solid-auth-fetcher'


import store from '../store/index';
import { login, fetch, getDefaultSession, onLogin } from '@inrupt/solid-client-authn-browser'
//import { getDefaultSession} from '@inrupt/solid-client-authn-browser'
import { getSolidDataset,  /*saveSolidDatasetAt,  universalAccess */} from "@inrupt/solid-client";
//import { session } from 'rdf-namespaces/dist/link';






export default {
    name: "Solid",
    computed: {
      podurl () {
        return store.state.podurl
      },
    },
    async created(){
        // if (this.podurl) {
        //     this.readCarList()
            
        // }

    //     //this.loginAndFetch();
    //     const status = await handleIncomingRedirect({ restorePreviousSession : true })
    //     if (status.isLoggedIn){
    //         console.log("a rediriger")
    //         console.log(status.webId)
    //         store.commit('set_webid', status.webId)
    //         const url = await getPodUrlAll(store.state.webid, { fetch: fetch });//"https://storage.inrupt.com/ba82ae53-d980-4257-a372-95b964cdf067/car"//session.info.webId+"/car"
    //         console.log("urls")
    //         console.log(url)
    //         store.commit('set_podurl', url[0] + "car")

    //         this.readCarList()
    //     }
    //     console.log("webid")
    //     console.log(store.state)
    },
    data() {
        return {
            login: false,
            // IDP options
            options: [
                'https://login.inrupt.com',
                'https://solidcommunity.net',
                'https://solidweb.org',
                'https://inrupt.net',
            ],
            // options: [{
            //     value: 'https://login.inrupt.com',
            //     label: 'https://login.inrupt.com'
            // }, {
            //     value: 'https://solidcommunity.net',
            //     label: 'Solid Community'
            // }, {
            //     value: 'https://solidweb.org',
            //     label: 'Solid web'
            // }, {
            //     value: 'https://inrupt.net/',
            //     label: 'https://inrupt.net'
            // }],
            //oidcIssuer: "https://login.inrupt.com",
                //oidcIssuer: "https://solidcommunity.net",
                //oidcIssuer: "https://solidweb.org",
                //oidcIssuer: "https://inrupt.net/",
            // Select IDP
            selectValue: "https://login.inrupt.com",
            // Your webId
            webId: "",
            // Your user name
            userName: "",
            // Profile object
            profile: null,
            // NotePad object
            notePad: null,
            // Input value of new note
            newNote: "",
            // Notes from your pod (only text)
            notes: []
        }
    },
    methods: {
        test(){
            console.log("nbv")
            console.log(getDefaultSession().info)

            console.log("nbv")
        },
        async loginAndFetch() {
            
            onLogin(this.test)

            /* const { fetch } = auth;
            let session = await auth.currentSession();
            let popupUri = 'https://solidcommunity.net/common/popup.html';
            if (!session){
                session = await auth.popupLogin({ popupUri });
            }
            store.commit('set_webid', session.webId)

            console.log("this.webId")
            console.log(session.webId)
            console.log(session)
            console.log(getDefaultSession().info)
 */




           /*  const id = new URL(window.location.href).searchParams.get("id")
            if (id) {
                console.log("ok")
                console.log(id)
                localStorage.setItem('solidClientAuthn:currentSession', id)
                let session = new Session({}, id)
                await session.handleIncomingRedirect();
                console.log(getDefaultSession().info)
                console.log("laaaaaaa")

            } */
            // 1. Call `handleIncomingRedirect()` to complete the authentication process.
            //    If called after the user has logged in with the Solid Identity Provider, 
            //      the user's credentials are stored in-memory, and
            //      the login process is complete. 
            //   Otherwise, no-op. 






            /* console.log("avant")

            console.log(window.location.href)
            console.log(getDefaultSession().info)
            console.log(localStorage.getItem('solidClientAuthn:currentSession'))
            await handleIncomingRedirect();
            
            console.log("apres")
            console.log(window.location.href)
            console.log(getDefaultSession().info)
            console.log(localStorage.getItem('solidClientAuthn:currentSession'))

            await new Promise(r => setTimeout(r, 10000));
            // 2. Start the Login Process if not already logged in.
            if (!getDefaultSession().info.isLoggedIn) {
                await login({
                // Specify the URL of the user's Solid Identity Provider;
                // e.g., "https://login.inrupt.com".
                oidcIssuer: "https://login.inrupt.com",
                // Specify the URL the Solid Identity Provider should redirect the user once logged in,
                // e.g., the current page for a single-page app.
                redirectUrl: window.location.href.split('?')[0]+"?id="+getDefaultSession().info.sessionId,
                // Provide a name for the application when sending to the Solid Identity Provider
                clientName: "BapLap"
                }); 
                await handleIncomingRedirect();
            console.log("3")

                console.log(getDefaultSession().info)
                console.log(window.location.href)
            
            await new Promise(r => setTimeout(r, 10000));
            } 

            store.commit('set_webid', getDefaultSession().info.webId)*/
                //console.log(store.state.webid)
            
                

            // console.log("await handleIncomingRedirect()")
            // console.log(await handleIncomingRedirect())
            // console.log("await handleIncomingRedirect()")


            if (!getDefaultSession().info.isLoggedIn) {
                await login({
                // Specify the URL of the user's Solid Identity Provider;
                // e.g., "https://login.inrupt.com".
                
                oidcIssuer: "https://login.inrupt.com",
                //oidcIssuer: "https://solidcommunity.net",
                //oidcIssuer: "https://solidweb.org",
                //oidcIssuer: "https://inrupt.net/",
                
                // Specify the URL the Solid Identity Provider should redirect the user once logged in,
                // e.g., the current page for a single-page app.
                redirectUrl: window.location.href,
                // Provide a name for the application when sending to the Solid Identity Provider
                clientName: "BapLap",
                }); 
            }
            store.commit('set_webid', getDefaultSession().info.webId)
            console.log("wxcvb")
            console.log(store.state.webid)
            console.log("wxcvb")



                
   /*         var session = await getSession()
            if (!session) {
                login({
                    oidcIssuer: "https://login.inrupt.com",
                    popUp: false,
                    redirect: window.location.href,
                    clientName: "BapLap"
                })
                
            }
            console.log("azertyhgfd");
            console.log(session);

           session = await getSession()

            console.log(session);
            
            
            store.commit('set_webid', "https://id.inrupt.com/"+session.webId) 

            console.log("laaaaa")

 */


            const url = await getPodUrlAll(store.state.webid, { fetch: fetch });//"https://storage.inrupt.com/ba82ae53-d980-4257-a372-95b964cdf067/car"//session.info.webId+"/car"
            console.log("urls")
            console.log(url)
            store.commit('set_podurl', url[0] + "car")

            this.readCarList()

            // universalAccess.getAgentAccess(
            //     this.podurl,         // Resource
            //     store.state.webid,   
            //     { fetch: fetch }                         // fetch function from authenticated session
            //     ).then((newAccess) => {
            //         logAccessInfo(store.state.webid, newAccess, this.podurl)
            //         if (!newAccess.read || !newAccess.write) {
            //             universalAccess.setAgentAccess(
            //             this.podurl,         // Resource
            //             store.state.webid,     // Agent
            //             { read: true, write: true, },          // Access object
            //             { fetch: fetch }                         // fetch function from authenticated session
            //             ).then((newAccess) => {
            //             logAccessInfo("new: " + store.state.webid, newAccess, this.podurl)
            //             });
            //         } 

            //         this.readCarList()
                    
            //     }
            // );

            // function logAccessInfo(agent, agentAccess, resource) {
            //     console.log(`For resource::: ${resource}`);
            //     if (agentAccess === null) {
            //         console.log(`Could not load ${agent}'s access details.`);
            //     } else {
            //         console.log(`${agent}'s Access:: ${JSON.stringify(agentAccess)}`);
            //     }
                
            // } 
        },
        async readCarList() {
            console.log(this.podurl)
            const myDataset = await getSolidDataset(this.podurl, 
                { fetch: fetch }
            );
            console.log(myDataset)

            const urls = getContainedResourceUrlAll(myDataset)
            const listeVoiture = []
            urls.forEach(element => {
                console.log(element.split('/'))

                if (element.endsWith("/")) {
                    const name = element.split('/')
                    listeVoiture.push(name[name.length-2]) //!!!!!!!!!!!!!!!!!!!! interdire nom de voiture avec / dedans
                }
            });
            console.log(listeVoiture)
            store.commit('set_carList', listeVoiture)
           /*  var file = await getFile(
                this.podurl+"/message.json",               // File in Pod to Read
                {fetch: fetch }       // fetch from authenticated session
            );
            Promise.resolve(file.text()).then(JSON.parse).then(console.log); */
        },
        
        // Event listener : selector on change
        selectProvider(event) {console.log(event)
            //this.selectValue = event;
        },
        // Submit IDP selector form & login user
        async submit() {
            if (!getDefaultSession().info.isLoggedIn) {
                await login({
                // Specify the URL of the user's Solid Identity Provider;
                // e.g., "https://login.inrupt.com".
                
                oidcIssuer: this.selectValue,
                //oidcIssuer: "https://solidcommunity.net",
                //oidcIssuer: "https://solidweb.org",
                //oidcIssuer: "https://inrupt.net/",
                
                // Specify the URL the Solid Identity Provider should redirect the user once logged in,
                // e.g., the current page for a single-page app.
                redirectUrl: window.location.href,
                // Provide a name for the application when sending to the Solid Identity Provider
                clientName: "BapLap",
                }); 
            }
            store.commit('set_webid', getDefaultSession().info.webId)
            const url = await getPodUrlAll(store.state.webid, { fetch: fetch });//"https://storage.inrupt.com/ba82ae53-d980-4257-a372-95b964cdf067/car"//session.info.webId+"/car"
            console.log("urls")
            console.log(url)
            store.commit('set_podurl', url[0] + "car")

            this.readCarList()
        },
        // Get webId by looking up session in localstorage
        async getWebId() {
            // let session = await auth.currentSession();
            // if (session) {
            //     return session.webId;
            // } else {
            //     return '';
            // }
        },
        // Get user name
        // async getName() {
        //     let webIdDoc = await fetchDocument(this.webId);
        //     let profile = webIdDoc.getSubject(this.webId);
        //     this.profile = profile;
        //     this.userName = profile.getString(foaf.name);
        //     store.commit('set_name', this.userName)
        // },
        // async getNotesList() {
        //     /* 1. Check if a Document tracking our notes already exists. */
        //     let publicTypeIndexRef = this.profile.getRef(solid.publicTypeIndex);
        //     console.log("a " + publicTypeIndexRef)
        //     console.log(this.profile)
        //     let publicTypeIndex = await fetchDocument(publicTypeIndexRef);
        //     console.log( publicTypeIndex)
        //     let notesListEntry = publicTypeIndex.findSubject(solid.forClass, schema.TextDigitalDocument);
        //     let noteslist = null;
        //     /* 2. If it doesn't exist, create it. */
        //     if (notesListEntry === null) {
        //         // We will define this function later:
        //         noteslist = await this.initialiseNotesList(this.profile, publicTypeIndex);
        //         this.notePad = noteslist;
        //         return noteslist;
        //     }
        //     /* 3. If it does exist, fetch that Document. */
        //     let notesListRef = notesListEntry.getRef(solid.instance);
        //     noteslist = await fetchDocument(notesListRef);
        //     this.notePad = noteslist;
        //     this.notes = noteslist.getAllSubjectsOfType(schema.TextDigitalDocument).map(function (note) {
        //         return note.getString(schema.text);
        //     });
        //     return noteslist;
        // },
        // async getMyData(){
        //     //const { session } = useSession();
        //     let session = await auth.currentSession();
            
        //     console.log("dfg")
        //     const mypods = "https://baptistel.inrupt.net/"//await getPodUrlAll(this.webID, { fetch: fetch });
        //     console.log("sdf")

        //     const fileURL = mypods+ "a.txt"
        //     console.log(fileURL)
        //     console.log(session)
        //     try {
        //         // file is a Blob (see https://developer.mozilla.org/docs/Web/API/Blob)
        //         const file = await getFile(
        //         fileURL,               // File in Pod to Read
        //         { fetch: session.fetch }       // fetch from authenticated session
        //         );

        //         console.log( `Fetched a ${getContentType(file)} file from ${getSourceUrl(file)}.`);
        //         console.log(`The file is ${isRawData(file) ? "not " : ""}a dataset.`);
        //         console.log(file)
        //     } catch (err) {
        //         console.log(err);
        //     }
        // },
        // Creates anew document that contain the notes, and adds it to the Public Type Index.
        // async initialiseNotesList(profile, typeIndex) {
        //     // Get the root URL of the user's Pod:
        //     let storage = profile.getRef(space.storage);
        //     // Decide at what URL within the user's Pod the new Document should be stored:
        //     let notesListRef = storage + 'public/notes.ttl';
        //     // Create the new Document:
        //     let notesList = createDocument(notesListRef);
        //     await notesList.save();
        //     // Store a reference to that Document in the public Type Index for `schema:TextDigitalDocument`:
        //     let typeRegistration = typeIndex.addSubject();
        //     typeRegistration.addRef(rdf.type, solid.TypeRegistration)
        //     typeRegistration.addRef(solid.instance, notesList.asRef())
        //     typeRegistration.addRef(solid.forClass, schema.TextDigitalDocument)
        //     await typeIndex.save([typeRegistration]);
        //     // And finally, return our newly created (currently empty) notes Document:
        //     return notesList;
        // },
        // async addNote() {
        //     // Initialise the new Subject:
        //     let newNote = this.notePad.addSubject();
        //     // Indicate that the Subject is a schema:TextDigitalDocument:
        //     newNote.addRef(rdf.type, schema.TextDigitalDocument);
        //     // Set the Subject's `schema:text` to the actual note contents:
        //     newNote.addString(schema.text, this.newNote);
        //     // Store the date the note was created (i.e. now):
        //     newNote.addDateTime(schema.dateCreated, new Date(Date.now()))
        //     let success = await this.notePad.save([newNote]);
        //     this.notes.push(this.newNote);
        //     return success;
        // },
        // Log out user
        logout() {
            //logout().then(() => console.log("Goodbye!"));
            this.login = false;
        }
    },
    mounted() {
        /* this.getWebId().then(webID => {
            if (webID !== '') {
                this.login = true;
            }
            this.webId = webID;
        }) */
    }
}
</script>

<style scoped>
.login {
    height: 100%;
    display: grid;
    place-items: center;
}
.submitButton {
    margin-left: 20px;
}
.notepad {
    display: block;
    height: 100%;
    width: 100%;
    margin-bottom: 30px;
    padding-top: 10px;
    padding-bottom: 50px;
    background: bisque;
    border-radius: 20px;
}
.notes {
    background: orange;
    text-align: center;
    width: 70%;
    padding-top: 15px;
    padding-bottom: 15px;
    margin: 30px auto auto;
}
.input {
    width: 70%;
}
</style>