<template>
    <validation-observer
      ref="observer"
      v-slot="{ invalid }"
    >
      <form @submit.prevent="submit">
        <v-row >
          <v-col >
        
            <v-combobox 
            label="fuel type"
            clearable
            v-model="type"
            :items="fuelType"/>
          </v-col>
            
          <v-col>
      
            <v-menu
              v-model="datePick"
              :close-on-content-click="false"
              :nudge-right="30"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date"
                  label="date of refuelling"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date"
                @input="datePick = false"
              ></v-date-picker>
            </v-menu>


          </v-col>
        </v-row>

        
        

        <validation-provider
        v-slot="{ errors }"
        name="Price"
        :rules="{
          required: true,
          regex: regex
        }"
      >
        <v-text-field
          v-model="price"
          :error-messages="errors"
          label="Price"
          suffix="€"
          placeholder="0.00"
          required
          @change="priceChange"
        ></v-text-field>
      </validation-provider>

      <validation-provider
        v-slot="{ errors }"
        name="Liters"
        :rules="{
          required: true,
          regex: regex
        }"
      >
        <v-text-field
          v-model="liters"
          :error-messages="errors"
          label="Liters"
          suffix="L"
          placeholder="0.00"
          required
          @change="litersChange"
        ></v-text-field>
      </validation-provider>

      <v-text-field
          v-model="litPrice"
          label="price/liter"
          suffix="€/L"
          placeholder="0.00"
        ></v-text-field>


        <v-container fluid class="ma-0 pa-0" >

        <v-row >
          <v-col>
      
      <validation-provider
        v-slot="{ errors }"
        name="partial"
        :rules="{
          required: true,
          regex: regex
        }"
      >
        <v-text-field
          v-model="partial"
          :error-messages="errors"
          label="partial"
          suffix="Km"
          placeholder="0.00"
          required
          @input="partialChange"
        ></v-text-field>
      </validation-provider>


      </v-col>
      <v-col>
        <validation-provider
        v-slot="{ errors }"
        name="total"
        :rules="{
          required: true,
          regex: regex
        }"
      >
        <v-text-field
          v-model="total"
          :error-messages="errors"
          label="total"
          suffix="Km"
          placeholder="0.00"
          :hint="'last refuel: ' + lastkm"
          required
          @input="totalChange"
          :disabled="carbu_type == 'gpl'"
        ></v-text-field>
      </validation-provider>
    </v-col>
      

    </v-row>
        
</v-container>


      

  
        <v-btn
          class="mr-4"
          type="submit"
          :disabled="invalid"
        >
          submit
        </v-btn>
        <v-btn @click="clear">
          clear
        </v-btn>
      </form>
    </validation-observer>
  </template>
  
  <script>
  import { required, max, regex } from 'vee-validate/dist/rules'
  import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
  import store from '../../../store/index';
  
    setInteractionMode('eager')
  
    extend('required', {
      ...required,
      message: '{_field_} can not be empty',
    })
  
    extend('max', {
      ...max,
      message: '{_field_} may not be greater than {length} characters',
    })
  
    extend('regex', {
      ...regex,
      message: '{_field_} {_value_} does not match {regex}',
    })
  
  
    export default {
      components: {
        ValidationProvider,
        ValidationObserver,
      },
      props: {
        carname: String
      },
      data: () => ({
        datePick: false,
        regex: '^[0-9]+[.,]?[0-9]*$',
        type: '',
        fuelType: [
          'SP95',
          'SP98',
          'E85',
          'diesel',
        ],
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10),
        price: '',
        liters: '',
        litPrice: '',
        total: '',
        partial: '',
      }),
      methods: {
        priceChange(){
            if (typeof(this.price) === 'string' && this.price.match(this.regex)) {
                this.price = parseFloat(this.price.replace(',', '.'))
                if (typeof(this.liters) === 'number') {
                    this.litPrice = this.price/this.liters
                }
            }
        },
        litersChange(){
            if (typeof(this.liters) === 'string' && this.liters.match(this.regex)) {
                this.liters = parseFloat(this.liters.replace(',', '.'))
                if (typeof(this.price) === 'number') {
                    this.litPrice = this.price/this.liters
                }
            }
        },
        totalChange(){
            if (typeof(this.total) === 'string' && this.total.match(this.regex)) {
                this.total = parseFloat(this.total.replace(',', '.'))
                this.partial = this.total - this.lastkm

            }
        },
        partialChange(){
          console.log("wxcv")
            if (typeof(this.partial) === 'string' && this.partial.match(this.regex)) {
                this.partial = parseFloat(this.partial.replace(',', '.'))
                this.total = this.lastkm + this.partial
            }
        },
        async writeFile(url, json) {
          console.log("aaaaaaaa")
          console.log(this.$parent)
            return await this.$parent.$parent.writeFile(url, json)
        },
        async submit () {
            console.log("valid")
            this.$refs.observer.validate()
            const json = {"type":this.type, "date":this.date, "price":this.price, "liters":this.liters, "litPrice":this.litPrice, "total":this.total, "partial":this.partial, "conso":this.liters/this.partial*100}
            const carbu = store.getters.get_carburant(this.carname)
            carbu.push(json)
            //await this.writeFile(store.state.podurl + "/" + this.carname + "/carburant.json", carbu)
            
            store.commit('set_carburant', {carname: this.carname, data: carbu})
            this.lastkm = this.total

        },
        clear () {
        // console.log( this.price)
        // console.log( this.litPrice)
          this.type = ''
          this.price = ''
          this.liters= ''
          this.litPrice= ''
          this.total= ''
          this.partial= ''
        },
      },
      watch: {
        invalid: function (val) {
        // when the hash prop changes, this function will be fired.
        console.log("val = " + val)
        } 
      },
      computed: {
        lastkm () {
          return store.getters.get_total_km(this.carname)
        },
        carbu_type () {
          return store.getters.get_type(this.carname)
        },
      },
    }
  </script>
  
  <style>
  
  </style>