<template>
  <validation-observer ref="observer" v-slot="{ invalid }">
    <form @submit.prevent="submit">
      <v-combobox label="Title" clearable v-model="title" :items="maintType" />
      <validation-provider
            v-slot="{ errors }"
            name="Price"
            :rules="{
              required: true,
              regex: regex,
            }"
          >
            <v-text-field
              v-model="price"
              :error-messages="errors"
              label="Price"
              suffix="€"
              placeholder="0.00"
              required
            ></v-text-field>
          </validation-provider>
      <v-row>
        

        <v-col>
          <v-menu
            v-model="datePick"
            :close-on-content-click="false"
            :nudge-right="30"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                label="Date of the maintenance"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              @input="datePick = false"
            ></v-date-picker>
          </v-menu>

        </v-col>

          <v-col style="display: flex; padding-left: 12px; margin-inline: auto">
            <label class="switch">
              <input type="checkbox" class="checkbox" v-model="remainderTime" />
              <div class="slider"></div>
              remainder
            </label>


            <v-menu
            style="flex-grow: 1; padding-left: 12px"
            v-if="remainderTime"
            :close-on-content-click="false"
            :nudge-right="30"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="timeRemainder"
                label="Date of the remainder"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="timeRemainder"
            ></v-date-picker>
          </v-menu>

          </v-col>


      </v-row>

      <v-container fluid class="ma-0 pa-0">
        <v-row>
          <v-col>
            <validation-provider
              v-slot="{ errors }"
              name="Km"
              :rules="{
                required: true,
                regex: regex,
              }"
            >
              <v-text-field
                v-model="Km"
                :error-messages="errors"
                label="Km"
                suffix="Km"
                placeholder="0.00"
                required
              ></v-text-field>
            </validation-provider>
          </v-col>

          <v-col style="display: flex; padding-left: 12px; margin-inline: auto">
            <label class="switch">
              <input type="checkbox" class="checkbox" v-model="remainderKm" />
              <div class="slider"></div>
              remainder
            </label>
            <validation-provider
              style="flex-grow: 1; padding-left: 12px"
              v-if="remainderKm"
              v-slot="{ errors }"
              name="nbKmRemainder"
              :rules="{
                required: true,
                regex: regex,
              }"
            >
              <v-text-field
                v-model="nbKmRemainder"
                :error-messages="errors"
                :hint="'remainder set at ' + nextKm"
                label="Remaind me in ..."
                suffix="Km"
                placeholder="0.00"
                required
              ></v-text-field>
            </validation-provider>
          </v-col>
        </v-row>
      </v-container>

      <v-textarea
        v-model="description"
        label="Description of the maintenance"
      ></v-textarea>

      <v-btn class="mr-4" type="submit" :disabled="invalid"> submit </v-btn>
      <v-btn @click="clear"> clear </v-btn>
    </form>
  </validation-observer>
</template>

<script>
import { required, max, regex } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";
import store from "../../../store/index";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "{_field_} can not be empty",
});

extend("max", {
  ...max,
  message: "{_field_} may not be greater than {length} characters",
});

extend("regex", {
  ...regex,
  message: "{_field_} {_value_} does not match {regex}",
});

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    carname: String,
  },
  data: () => ({
    datePick: false,
    //maintType: ['vidange'],
    regex: "^[0-9]+[.,]?[0-9]*$",
    title: "",
    price: "",
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substring(0, 10),
    remainderTime: false,
    timeRemainder: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substring(0, 10),
    Km: "",
    remainderKm: false,
    nbKmRemainder: "",
    description: "",
  }),
  methods: {
    async writeFile(url, json) {
      return await this.$parent.$parent.writeFile(url, json);
    },
    async submit() {
      console.log("valid");
      this.$refs.observer.validate();
      const json = {
        title: this.title,
        price: this.price,
        date: this.date,
        Km: this.Km,
        description: this.description,
      };
      if (this.remainderKm) {
        json["nextKm"] = this.nextKm;
      } else {
        json["nextKm"] = "none";
      }
      if (this.remainderTime) {
        json["nextTime"] = this.timeRemainder;
      } else {
        json["nextTime"] = "none";
      }
      const maint = store.getters.get_maintenance(this.carname);
      maint.push(json);

      store.commit("set_maintenance", { carname: this.carname, data: maint });
    },
    clear() {
      // console.log( this.price)
      // console.log( this.litPrice)
      this.title = "";
      this.price = "";
      this.Km = "";
      this.nbKmRemainder = "";
      this.description = "";
    },
  },
  watch: {
    invalid: function (val) {
      // when the hash prop changes, this function will be fired.
      console.log("val = " + val);
    },
  },
  computed: {
    lastkm() {
      return store.getters.get_total_km(this.carname);
    },
    maintType() {
      return store.getters.get_maintenance_type();
    },
    nextKm() {
      return parseInt(this.Km) + parseInt(this.nbKmRemainder);
    },
  },
};
</script>

<style></style>
