import Vue from 'vue'
import App from './App.vue'
import store from './store'
import vuetify from './plugins/vuetify'
import { Simplert } from 'vue2-simplert-plugin';
import 'vue2-simplert-plugin/dist/vue2-simplert-plugin.min.css';

Vue.use(Simplert)

Vue.config.productionTip = false

new Vue({
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')


// head.script.push({
//   async: true,
//   src: 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js',
//   dataAdClient: "ca-pub-3842278734755114"
// });
