<!-- <template>
  <span>
    <v-tabs
      v-model="tab"
      background-color="primary"
      dark
    >
      <v-tab
        v-for="item in items"
        :key="item.tab"
      >
        {{ item.tab }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item
        v-for="item in items"
        :key="item.tab"
      >
        <v-card flat>
          <v-card-text>{{ item.content }}</v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
</span>
</template>

<script>
//import store from '../store/index';

export default {
    data () {
      return {
        tab: null,
        items: [
          { tab: 'One', content: 'Tab 1 Content' },
          { tab: 'Two', content: 'Tab 2 Content' },
          { tab: 'Three', content: 'Tab 3 Content' },
          { tab: 'Four', content: 'Tab 4 Content' },
          { tab: 'Five', content: 'Tab 5 Content' },
          { tab: 'Six', content: 'Tab 6 Content' },
          { tab: 'Seven', content: 'Tab 7 Content' },
          { tab: 'Eight', content: 'Tab 8 Content' },
          { tab: 'Nine', content: 'Tab 9 Content' },
          { tab: 'Ten', content: 'Tab 10 Content' },
          { tab: 'Ten', content: 'Tab 10 Content' },
          { tab: 'Ten', content: 'Tab 10 Content' },
          { tab: 'Ten', content: 'Tab 10 Content' },
          { tab: 'Ten', content: 'Tab 10 Content' },
          { tab: 'Ten', content: 'Tab 10 Content' },
          { tab: 'Ten', content: 'Tab 10 Content' },
          { tab: 'Ten', content: 'Tab 10 Content' },
          { tab: 'Ten', content: 'Tab 10 Content' },
          { tab: 'Ten', content: 'Tab 10 Content' },
          { tab: 'Ten', content: 'Tab 10 Content' },
        ],
      }
    },
}
</script>

<style>

</style> -->

<template>
  <v-app>
    <v-app-bar app clipped-left color="#263241">
      <v-img
        src="../assets/logo_full.png"
        alt="logo"
        max-width="20%"
        max-height="48"
        class="d-none d-sm-flex mr-2 pr-1"
      />

      <v-tabs
        v-model="tab"
        centered
        dark
        class="d-none d-sm-flex"
        color="#CBB26A"
      >
        <v-tabs-slider></v-tabs-slider>
        <v-tab key="home" href="#home"> Home </v-tab>
        <v-tab v-for="car in carList" :key="car" :href="'#' + car">
          {{ car }}
        </v-tab>
      </v-tabs>

      <!-- <v-menu offset-y transition="slide-y-transition" >
            <template v-slot:activator="{ on, attrs }">
                <v-app-bar-nav-icon class="d-flex " v-bind="attrs"
                v-on="on"></v-app-bar-nav-icon>
                
            </template>
            <v-list nav dense>
                <v-list-item-group>
                    <v-list-item v-for="(item, index) in items" :key="item">
                    <v-list-item-title @click="tab = index">{{ item }}</v-list-item-title>
                    </v-list-item>
        
                </v-list-item-group>
            </v-list>
        </v-menu> -->

      <v-app-bar-nav-icon
        dark
        @click="drawer = !drawer"
        class="d-flex d-sm-none"
      ></v-app-bar-nav-icon>
      <v-img
        src="../assets/logo_full.png"
        alt="logo"
        max-width="40%"
        max-height="48"
        class="d-flex d-sm-none ml-4"
      />
      <v-btn @click="logout" right fixed>logout</v-btn>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" class="d-sm-none d-flex" clipped app>
      <v-tabs
        vertical
        v-model="tab"
        color="#CBB26A"
        height="100%"
        class="main-gray"
      >
        <v-tab key="home" href="#home"> Home </v-tab>
        <v-tab
          left
          v-for="car in carList"
          :key="car"
          @click="drawer = false"
          :href="'#' + car"
        >
          {{ car }}
        </v-tab>
      </v-tabs>
    </v-navigation-drawer>

    <!-- Add a navigation bar -->
    <!-- <v-navigation-drawer v-model="drawer" class="d-sm-none"  app  >
        <v-list nav dense>
          <v-list-item-group>
            <v-list-item >
              <v-list-item-title>close</v-list-item-title>
            </v-list-item>
            <v-list-item v-for="(item, index) in items" :key="item">
              <v-list-item-title @click="tab = index">{{ item }}</v-list-item-title>
            </v-list-item>
  
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer> -->
    <!-- Navigation bar ends -->

    <v-content app class="ma-2">
      <v-tabs-items v-model="tab" class="align-center">
        <v-tab-item value="home">
          <Home @goToRefuel="goToRefuel" />
        </v-tab-item>
        <v-tab-item v-for="car in carList" :key="car" :value="car">
          <Car :carname="car" :openRefuel="openRefuel" />
        </v-tab-item>
      </v-tabs-items>
    </v-content>
  </v-app>
</template>
<script>
import store from "../store/index";
import Car from "./car.vue";
import Home from "./home.vue";
import Vue from "vue";
// import { handleIncomingRedirect } from "@inrupt/solid-client-authn-browser";
import { getSolidDataset, getContainedResourceUrlAll} from "@inrupt/solid-client";
import { fetch } from '@inrupt/solid-client-authn-browser'

Vue.mixin({
  methods: {},
});
export default {
  components: {
    Car,
    Home,
  },
  // async beforeCreate(){
        

  //       //this.loginAndFetch();
  //       console.log("66666666")
  //       const status = await handleIncomingRedirect({ restorePreviousSession : true })
  //       console.log("66666666")
  //       console.log(status.isLoggedIn)

  // },
  data() {
    return {
      drawer: false,
      tab: null,
      openRefuel: false,
    };
  },
  computed: {
    carList() {
      return store.state.carList;
    },
    podurl () {
      return store.state.podurl
    },
  },
  async created(){
    console.log("nav")
    console.log(this.podurl)
    console.log("nav")
      if (this.podurl) {
        this.readCarList()
      }
    },
  methods: {
      async readCarList() {
    console.log("nav2")
        console.log(this.podurl)
    console.log("nav2")
        const myDataset = await getSolidDataset(this.podurl, 
            { fetch: fetch }
        );
        console.log(myDataset)

        const urls = getContainedResourceUrlAll(myDataset)
        const listeVoiture = []
        urls.forEach(element => {
            console.log(element.split('/'))

            if (element.endsWith("/")) {
                const name = element.split('/')
                listeVoiture.push(name[name.length-2]) //!!!!!!!!!!!!!!!!!!!! interdire nom de voiture avec / dedans
            }
        });
        console.log(listeVoiture)
        store.commit('set_carList', listeVoiture)
        /*  var file = await getFile(
            this.podurl+"/message.json",               // File in Pod to Read
            {fetch: fetch }       // fetch from authenticated session
        );
        Promise.resolve(file.text()).then(JSON.parse).then(console.log); */
    },
    // Create an array the length of our items
    // with all values as true
    logout() {
      this.$parent.logout();
    },
    goToRefuel(carName) {
      console.log(carName);
      this.openRefuel = false;
      this.openRefuel = true;
      this.tab = carName;
    },
    // Reset the panel
  },
};
</script>

<style>
.main-gray {
  color: #263241 !important;
}
</style>
