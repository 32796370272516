var render = function render(){var _vm=this,_c=_vm._self._c;return _c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function({ invalid }){return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('v-row',[_c('v-col',[_c('v-combobox',{attrs:{"label":"fuel type","clearable":"","items":_vm.fuelType},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})],1),_c('v-col',[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":30,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"date of refuelling","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.datePick),callback:function ($$v) {_vm.datePick=$$v},expression:"datePick"}},[_c('v-date-picker',{on:{"input":function($event){_vm.datePick = false}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1)],1),_c('validation-provider',{attrs:{"name":"Price","rules":{
          required: true,
          regex: _vm.regex
        }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Price","suffix":"€","placeholder":"0.00","required":""},on:{"change":_vm.priceChange},model:{value:(_vm.price),callback:function ($$v) {_vm.price=$$v},expression:"price"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Liters","rules":{
          required: true,
          regex: _vm.regex
        }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Liters","suffix":"L","placeholder":"0.00","required":""},on:{"change":_vm.litersChange},model:{value:(_vm.liters),callback:function ($$v) {_vm.liters=$$v},expression:"liters"}})]}}],null,true)}),_c('v-text-field',{attrs:{"label":"price/liter","suffix":"€/L","placeholder":"0.00"},model:{value:(_vm.litPrice),callback:function ($$v) {_vm.litPrice=$$v},expression:"litPrice"}}),_c('v-container',{staticClass:"ma-0 pa-0",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"name":"partial","rules":{
          required: true,
          regex: _vm.regex
        }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"partial","suffix":"Km","placeholder":"0.00","required":""},on:{"input":_vm.partialChange},model:{value:(_vm.partial),callback:function ($$v) {_vm.partial=$$v},expression:"partial"}})]}}],null,true)})],1),_c('v-col',[_c('validation-provider',{attrs:{"name":"total","rules":{
          required: true,
          regex: _vm.regex
        }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"total","suffix":"Km","placeholder":"0.00","hint":'last refuel: ' + _vm.lastkm,"required":"","disabled":_vm.carbu_type == 'gpl'},on:{"input":_vm.totalChange},model:{value:(_vm.total),callback:function ($$v) {_vm.total=$$v},expression:"total"}})]}}],null,true)})],1)],1)],1),_c('v-btn',{staticClass:"mr-4",attrs:{"type":"submit","disabled":invalid}},[_vm._v(" submit ")]),_c('v-btn',{on:{"click":_vm.clear}},[_vm._v(" clear ")])],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }