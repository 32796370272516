<template>
  <span>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      dense
      
      sort-by="date"
      sort-desc
      :headers="headers"
      :items="maintenance"
      :search="search"
      :items-per-page="5"
      class="elevation-1"
    >
      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
  </span>
</template>
  
<script>
  import store from '../../../store/index';

  
    export default {
      components: {
       
      },
      props: {
        carname: String
      },
      data: () => ({
        headers: [
          { text: 'Date', value: 'date'},
          { text: 'Title', value: 'title' },
          { text: 'Price (€)', value: 'price' },
          { text: 'Km', value: 'Km' },
          { text: 'remainder Km', value: 'nextKm' }, 
          { text: 'remainder Time', value: 'nextTime' }, 
          { text: 'Actions', value: 'actions', sortable: false },
        ],
        search: '',
      }),
      methods: {
      
        async deleteItem (item) {
          const carbu = store.getters.get_maintenance(this.carname)
          this.editedIndex = carbu.indexOf(item)
          carbu.splice(this.editedIndex, 1)
          
          store.commit('set_maintenance', {carname: this.carname, data: carbu})
          
        },
       
      },
      computed: {
        maintenance () {
          return store.getters.get_maintenance(this.carname)
        },
      },
       
    }
</script>

<style>

</style>