import Vue, { del } from 'vue'
import Vuex from 'vuex'
import {overwriteFile} from "@inrupt/solid-client";
import {fetch} from '@inrupt/solid-client-authn-browser'
import createPersistedState from "vuex-persistedstate";
import { createStore } from 'vuex-extensions'



Vue.use(Vuex)
async function writeFile(url, json) {
  const blob = new Blob([JSON.stringify(json)], { type: "application/json" })
  console.log(await overwriteFile( url, blob, { type: "application/json", fetch: fetch })); 
  return
}

export default createStore(Vuex.Store, {
  plugins: [createPersistedState()],
  state: {
    name: "",
    webid: "",
    podurl: "",
    carList: [],
    carburant: {},
    maintenance: {},
    totalKm: {},
    type: {}
  },

  mutations: {
    set_name(state, name){
      state.name = name
    },
    set_podurl(state, url){
      state.podurl = url
    },set_webid(state, webid){
      state.webid = webid
    },
    set_carList(state, carList){
      state.carList = carList
      carList.forEach(car => {
        state.carburant[car] = []
        state.maintenance[car] = []
      });
    },
    add_car(state, car){
      state.carList.push(car)
      // state.carburant[car] = []
      // console.log("add_car")
      // console.log(state.carburant)
    },
    set_type(state, payload){
      state.type[payload.carname] = payload.data
    },
    async set_carburant(state, payload){

      var lastkm = 0
      const carbu = payload.data
      var updated = []
      var tri = {}
      carbu.forEach(element => {
        tri[element.total] = element
      });
      Object.keys(tri).sort().forEach(function(k) {
        console.log(k, tri[k]);
        tri[k]["partial"] = tri[k]["total"] - lastkm
        tri[k]["conso"] = tri[k]["liters"] / tri[k]["partial"] * 100
        updated.push(tri[k])
        lastkm = tri[k]["total"]
      });

   
      const t = state.carburant

      t[payload.carname] = carbu
      state.carburant = {...t}

      const t2 = state.totalKm
      t2[payload.carname] = lastkm
      state.totalKm = {...t2}
      
      await writeFile(state.podurl + "/" + payload.carname + "/carburant.json", carbu)

    },
    async set_maintenance(state, payload){

      const maint = payload.data
      
   
      const t = state.maintenance

      t[payload.carname] = maint
      state.maintenance = {...t}
      console.log("write")
      console.log(maint)
      await writeFile(state.podurl + "/" + payload.carname + "/maintenance.json", maint)

    },
    del_carburant(state, id, index){
      del(state.carburant[id],index)
    },
    del_maintenance(state, id, index){
      del(state.maintenance[id],index)
    },
  },
  getters: {
    get_carburant: (state) => (id) => {
      return state.carburant[id]
    },
    get_maintenance: (state) => (id) => {
      return state.maintenance[id]
    },
    get_total_km: (state) => (id) => {
      return state.totalKm[id]
    },
    get_maintenance_type: (state) => (id) => {
      return Object.values(state.maintenance).flat(1).map(maint => maint['title']);
    },
    get_type: (state) => (id) => {
      return state.type[id]
    },
  },
  actions: {
    logout() {
       this.state.webid = ""
    }
  },
  modules: {
    // sub: {
    //   actions: {
    //     logout() {
       

    //     }
    //   }
    // }
  }
})
