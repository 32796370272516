<template>
  <v-app id="inspire">
    <Solid v-if="!isWebid && !this.waitingscreen" />
    <Navcar v-if="isWebid && !this.waitingscreen" />

    <span v-if="false">
      <v-system-bar app>
        <v-spacer></v-spacer>

        <v-icon>mdi-square</v-icon>

        <v-icon>mdi-circle</v-icon>

        <v-icon>mdi-triangle</v-icon>
      </v-system-bar>

      <v-navigation-drawer app clipped right>
        <v-list v-if="isName">
          <v-list-item v-for="n in 5" :key="n" link>
            <v-list-item-content>
              <v-list-item-title>Item {{ n }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>

      <v-main>
        <v-container fluid>
          {{ isName }}
          <!-- <Solid/> -->
        </v-container>
      </v-main>

      <v-footer app color="transparent" height="72" inset>
        <v-text-field
          background-color="grey lighten-1"
          dense
          flat
          hide-details
          rounded
          solo
        ></v-text-field>
      </v-footer>
    </span>
  </v-app>
</template>
<script
  async
  src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3842278734755114"
  crossorigin="anonymous"
></script>
<script>
import Vue from "vue";
import { handleIncomingRedirect, logout, fetch } from "@inrupt/solid-client-authn-browser";
//import {logout, fetch } from 'solid-auth-fetcher'
import {getPodUrlAll} from "@inrupt/solid-client";

import { overwriteFile, getFile } from "@inrupt/solid-client";

import Solid from "./components/Solid";
import Navcar from "./components/Navcar";

import store from "./store/index";


Vue.mixin({
  methods: {
    async logout() {
      console.log("logout");
      console.log(await logout());
      store.commit("set_webid", "");
      store.commit("set_podurl", "");
      sessionStorage.clear();
      window.localStorage.clear();
      console.log("store.state.carList")
      console.log(store.state.carList)
    },
    async writeFile(url, json) {
      const blob = new Blob([JSON.stringify(json)], {
        type: "application/json",
      });
      console.log(
        await overwriteFile(url, blob, {
          type: "application/json",
          fetch: fetch,
        })
      );
      console.log("write");
      console.log(json);
      console.log(url);
      console.log("write");
      return;
    },
    async readFile(url) {
      try {
        var file = await getFile(
          url, // File in Pod to Read
          { fetch: fetch } // fetch from authenticated session
        );
        return file.text();
      } catch (err) {
        console.log("err");
        console.log(err.statusCode);
        if (err.statusCode == 401) {
          store.dispatch('logout')
        }
      }
      
    },
  },
});

export default {
  name: "App",
  
  computed: {
    isName() {
      //Accessing the store's state
      console.log("azertj");
      return store.state.name;
    },
    isWebid() {
      return store.state.webid;
    },
  },
  async created(){
    this.waitingscreen = true
    store.commit('set_webid', "")
    store.commit('set_podurl', "")
        
        //this.loginAndFetch();
        const status = await handleIncomingRedirect({ restorePreviousSession : true })
        console.log("status")
        console.log(status)
        if (status.isLoggedIn){
            console.log("a rediriger")
            console.log(status.webId)
            const url = await getPodUrlAll(status.webId, { fetch: fetch });//"https://storage.inrupt.com/ba82ae53-d980-4257-a372-95b964cdf067/car"//session.info.webId+"/car"
            console.log("urls")
            console.log(url)
            store.commit('set_podurl', url[0] + "car")
            store.commit('set_webid', status.webId)

            // this.readCarList()
        }else{
            store.commit("set_webid", "");
            store.commit('set_podurl', "")

            sessionStorage.clear();
        }
        this.waitingscreen = false

        console.log("webid")
        console.log(store.state)
    },
  components: {
    Solid,
    Navcar,
  },
  data() {
    return {
      waitingscreen: false,
    }
  },
  methods: {},
};
</script>
