<template>
    <span>
      <v-expansion-panels multiple accordion class="pa-1"  v-model="open">
              <v-expansion-panel >
                  <v-expansion-panel-header>add maintenance</v-expansion-panel-header>
                  <v-expansion-panel-content>
                      <AddMaintenance :carname="carname"/>
                  </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel >
                  <v-expansion-panel-header>maintenance history</v-expansion-panel-header>
                  <v-expansion-panel-content>
                      <maintenance-history :carname="carname"/>
  
                  </v-expansion-panel-content>
              </v-expansion-panel>
          </v-expansion-panels>
    </span>
  </template>
  
  <script>
  import AddMaintenance from './entretien/addMaintenance.vue';
  import MaintenanceHistory from './entretien/maintenanceHistory.vue';
  
  export default {
      props: {
        carname: String
      },
      
      data: () => ({
          open: [],
      }),
      components: {
        AddMaintenance,
        MaintenanceHistory,
      },
      computed: {
      },
  }
  </script>
  
  <style>
  
  </style>