<template>
    <span>
    <simplert />
        
        this is the page of {{name}}<br/>
        {{type}}<br/>
        {{description}}

        <v-expansion-panels  v-model="open" multiple class="pa-1" >
            <v-expansion-panel>
                <v-expansion-panel-header>Fuel</v-expansion-panel-header>
                <v-expansion-panel-content id="expansion-panel-content-low-paddind">
                    <Carburant :carname="carname" :openRefuel="openRefuel"/>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel >
                <v-expansion-panel-header>Maintenance</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <Entretien :carname="carname"/>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <v-btn @click="delCar" right fixed >delete</v-btn>
        <!-- <v-btn @click="test" right fixed >test</v-btn> -->
    </span>
</template>

<script>
import store from '../store/index';
import {deleteSolidDataset, getContainedResourceUrlAll} from "@inrupt/solid-client";
import {fetch} from '@inrupt/solid-client-authn-browser'
import { getSolidDataset} from "@inrupt/solid-client";

import Carburant from './car/carburant.vue'
import Entretien from './car/entretien.vue'



export default {
    props: {
        carname: String,
        openRefuel: Boolean,
        
    },
    watch: {
        openRefuel: {
            immediate: true, 
            handler (val) {
                console.log("openRefuel")
                if (val && !this.open.includes(0)) {
                    this.open.push(0)
                }
            }
        }
    },
    components: {
      Carburant,
      Entretien
    },
    data: () => ({
        open: [],
        name: '',
        type: '',
        description: ''
    }),
    async mounted(){
        
        var res = this.$parent.readFile(store.state.podurl + "/" + this.carname + "/general.json")
        res = JSON.parse(await Promise.resolve(res))

        console.log(res)

        this.name = res.name
        this.type = res.type
        this.description = res.description
        store.commit('set_type', {carname: this.carname, data: this.type})


        //fuel
        var res = this.$parent.readFile(store.state.podurl + "/" + this.carname + "/carburant.json")
        res = JSON.parse(await Promise.resolve(res))
        store.commit('set_carburant', {carname: this.carname, data: res})

        //maintenance
        var res = this.$parent.readFile(store.state.podurl + "/" + this.carname + "/maintenance.json")
        res = JSON.parse(await Promise.resolve(res))
        var today = new Date()
        res.forEach(element => {
            if (element["nextTime"] != "none" && element["nextTime"] != "done"){
                var remainder = Date.parse(element["nextTime"])
                if (today > remainder) {
                    let confirmFn = function(carname) {
                        element["nextTime"] = "done"
                        store.commit('set_maintenance', {carname: carname, data: res})

                    }
                    let obj = {
                        title: 'Remainder',
                        message: `${element["title"]} was du to ${element["nextTime"]}`,
                        useConfirmBtn: true,
                        customConfirmBtnText: 'done',
                        customCloseBtnText: 'remaind me later',
                        onConfirm: confirmFn(this.carname)
                    }
                    this.$Simplert.open(obj)
                }
            }
        });
        store.commit('set_maintenance', {carname: this.carname, data: res})

    },
    // computed: {
    //     normalizedSize: function () {
    //         console.log("openRefuel")
    //         console.log(this.openRefuel)
    //         if (this.openRefuel && !this.open.includes(0)) {
    //             this.open.push(0)
    //         }
    //         return this.openRefuel
    //     }
    // },
    methods: {
        test(){
            let confirmFn = function() {
                        alert("I am Confirmed")
                    }
                    let obj = {
                        title: 'Alert Title',
                        message: 'Alert Message',
                        type: 'info',
                        useConfirmBtn: true,
                        onConfirm: confirmFn
                    }
                    console.log(this.$Simplert)
                    this.$Simplert.open(obj)
        },
        // Create an array the length of our items
        // with all values as true
        async delCar () {
            try {
                const myDataset = await getSolidDataset(store.state.podurl + "/" + this.carname, 
                    { fetch: fetch }
                );
                const urls = getContainedResourceUrlAll(myDataset)
                for (const element of urls){
                    console.log(element.split('/'))
                    await deleteSolidDataset(
                        element, 
                        { fetch: fetch }           // fetch function from authenticated session
                    );
                }


                await deleteSolidDataset(
                    store.state.podurl + "/" + this.carname, 
                    { fetch: fetch }           // fetch function from authenticated session
                );
                const listeVoiture = store.state.carList
                var index = listeVoiture.indexOf(this.carname);
                if (index > -1) {
                    listeVoiture.splice(index, 1);
                }
                store.commit('set_carList', listeVoiture)
                
            } catch (error) {
                console.log(error)
            }
        },
        // Reset the panel
    
    },
}
</script>

<style>

</style>